<template>
  <div class="eligibilityState">
    <h2 class="question-title">
      Let’s make sure we’re available in your state
    </h2>
    <b-row>
      <b-col>
        <div
          role="group"
          class="group-mb"
        >
          <label
            for="state"
            class="label-text"
          >What state do you live in?</label>
          <v-select
            v-model="state"
            class="custom-input-style fs-mask"
            append-to-body
            :calculate-position="withPopper"
            label="text"
            :options="states"
            :reduce="(states) => states.value"
            :searchable="true"
            placeholder="Select state"
          >
            <template #open-indicator="{ attributes }">
              <b-icon
                v-bind="attributes"
                icon="chevron-down"
              />
            </template>
            <span
              v-if="states.length == 0"
              slot="no-options"
            >Loading...</span>
          </v-select>
        </div>
        <!-- Terms and conditions -->
        <b-form-checkbox
          id="terms"
          v-model="terms"
          class="checkbox-label"
          name="checkbox-1"
          value="accepted"
          unchecked-value="not_accepted"
        >
          <span>I have read and agree to these </span>
          <a
            href="#"
            @click.prevent="termsModal = true"
          >
            Terms &amp; Conditions,
          </a>
          &nbsp;
          <a
            href="#"
            @click.prevent="termsModal = true"
          >Privacy Policy,</a>
          and
          <a
            href="#"
            @click.prevent="termsModal = true"
          >Telehealth Consent</a>
        </b-form-checkbox>
        <b-button
          pill
          variant="info"
          class="mb-3 slight-rounded"
          :disabled="disabled"
          @click="submitState"
        >
          Continue
        </b-button>
        <small
          class="d-block"
        >Already have an account?
          <router-link to="/login">Sign In</router-link></small>
      </b-col>
    </b-row>

    <TrustPilotHorizontalLogo />

    <!-- Modals -->
    <TermsModal
      :show="termsModal"
      header-class="terms-title"
      body-class="terms-body"
      @exit="termsModal = false"
    >
      <template #header>
        <h1 class="h2 mb-0 heading text-primary pr-4">
          Terms &amp; Conditions, Privacy Policy and Telehealth Consent
        </h1>
      </template>
      <template #body>
        <terms />
        <b-row>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              class="slight-rounded"
              pill
              variant="info"
              style="min-width: 250px"
              @click="agreeTerms()"
            >
              I Agree
            </b-button>
          </b-col>
        </b-row>
      </template>
    </TermsModal>
    <div
      class="bg-overlay"
      @click="termsModal = false"
    ></div>

    <modal
      id="agreement"
      v-model="showModal"
      modal-class="agreement"
      size="sm"
      centered
      hide-header
      hide-footer
    >
      <h2 class="mb-0 text-center link">
        Winona’s treatments require our customers to agree to the terms
      </h2>
      <!-- <b-button @click="$bvModal.hide('agreement')">Hide Modal</b-button> -->
    </modal>
  </div>
</template>

<script>
import popper from '@/assets/js/popper.js';
import formValidation from '@/components/form/mixins';
import analyticsMixin from '@/mixins/analytics';

import { LookUpService } from '@/services/lookup.service';
import Terms from '@/components/partials/Terms';
import TermsModal from '@/components/partials/SideModal';
import TrustPilotHorizontalLogo from '@/components/onboarding/badges/TrustpilotHorizontalLogo.vue';

export default {
  components: {
    Terms,
    TermsModal,
    TrustPilotHorizontalLogo,
  },
  mixins: [analyticsMixin, formValidation, popper],
  props: {
    onboardingflow: {
      type: String,
      default: 'flowA',
    },
    preAnswer: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      state: '',
      states: [],
      stateData: [],
      showModal: false,
      termsModal: false,
      terms: '',
      disabled: false,
    };
  },
  async mounted() {
    if (this.preAnswer) {
      this.state = this.preAnswer;
    }

    window.analytics.ready(async () => {
      this.$_analytics_track('Test Start', {
        Name: 'stateSelectionTrustpilot',
        anonymousId: window.analytics.user().anonymousId(),
      });
    });

    this.$_analytics_track(
      'Onboarding Screen Viewed',
      {
        // customerId: <Customer ID.>,
        screen: '1.1-A',
        onboardingflow: this.onboardingflow,
      }
    );
    this.getStates();
  },
  methods: {
    async getStates() {
      const { data } = await LookUpService.getLookUp('state');
      // Raw state data
      this.stateData = data;
      // State data for options
      data.forEach(data => {
        this.states.push({ value: data.id, text: data.name });
      });
    },
    async submitState() {
      if (this.terms == '' || this.terms == 'not_accepted') {
        this.toggleModal();
      } else if (this.state == '') {
        this.$emit('showAlert', 'Please choose a state.');
      } else {
        const selectedState = this.stateData.filter(
          state => state.id == this.state
        )[0];
        if (!selectedState) {
          this.$emit('showAlert', 'Please choose a state.');
          return;
        }
        this.$emit('setParams', 'state', selectedState.id);
        this.$emit('setStateName', selectedState.name);

        // WIN-31756
        // Fire state selected regardless of support
        this.$_analytics_track(
          'State Selected',
          {
            state: this.state,
            ...(await this.$store.dispatch('getVoluumDetails')),
          },
          {
            referrer: this.$store.dispatch('getEverflowDetails'),
          }
        );
        if (selectedState?.supported) {
          // this.$emit('showLoader', 3)
          this.$emit('setStep', 'email');
        } else {
          this.$_analytics_track(
            'Onboarding Reject',
            {
              question: 'What state do you live in?',
              answer: this.state,
              onboardingflow: this.onboardingflow,
            },
          );

          this.$emit('setStep', 'waitlist');
        }
      }
    },
    toggleModal() {
      this.$root.$emit('bv::show::modal', 'agreement');
    },
    agreeTerms() {
      // this.$root.$emit("bv::hide::modal", "terms");
      this.termsModal = false;
      this.terms = 'accepted';
    },
  },
};
</script>

<style lang="scss" scoped>
.vs__dropdown-toggle {
  height: 52px;
}

.checkbox-label a {
  cursor: pointer;
  color: #a783ff;
  text-decoration: none;

  &:hover {
    color: #1a6a72 !important;
  }
}
</style>
