<template>
  <div class="trustpilot-container mt-5">
    <img
      class="trustpilot-placeholder"
      src="@/assets/images/onboarding/trustpilot-score.png"
      alt="Trustpilot"
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.trustpilot-container {
  width: 100%;
}

.trustpilot-placeholder {
  margin-bottom: 1rem;
  max-width: 372px;
}

@include media-breakpoint-down(md) {
  .trustpilot-container {
    justify-content: center;
    text-align: center;
  }
  .trustpilot-placeholder {
    margin-bottom: 1rem;
    width: 100%;
  }
}
</style>