import { render, staticRenderFns } from "./Email.vue?vue&type=template&id=57bfe2bb&scoped=true"
import script from "./Email.vue?vue&type=script&lang=js"
export * from "./Email.vue?vue&type=script&lang=js"
import style0 from "./Email.vue?vue&type=style&index=0&id=57bfe2bb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57bfe2bb",
  null
  
)

export default component.exports